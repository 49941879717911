import '../styles/main.css';
import '../styles/styles.css';
import '../styles/welcome-modal.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";
import { useEffect, useState } from 'react';
import 'react-awesome-slider/dist/styles.css';
import YouTube from 'react-youtube';

export const Gallery = (props) => {
    const [images, setImages] = useState([]);
    useEffect(()=>{
        const galeri = [];
        for(let i = 1; i < 22; i++) {
            galeri.push({
                image1: require(`../assets/images/galeri/${i}.jpg`),
                thubnail1: require(`../assets/images/galeri/thubnail/${i}.jpg`),
                image2: require(`../assets/images/galeri/${i+1}.jpg`),
                thubnail2: require(`../assets/images/galeri/thubnail/${i+1}.jpg`)
            });
        }
        setImages(galeri);
    },[]);

    const onPaused = () => {
        props.setMusicPlayer(true);
    }

    const onStop = () => {
        props.setMusicPlayer(true);
    }

    const onPlay = () => {
        props.setMusicPlayer(false);
    }

    const opts = {
        height: '230',
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        //   controls: 0
        },
    };

    return(
       <div className='mt-3'>
        <ScrollAnimation duration={2} animateIn='fadeInUp' animateOnce={true}>
            <div className='font-gv text-primary mb-md' style={{fontSize: '24pt'}}>Our Gallery</div>
        </ScrollAnimation>

        <ScrollAnimation duration={2} delay={2} animateIn='fadeInUp' animateOnce={true}>
            <div className='text-normal text-primary mt-md mb-md' style={{lineHeight: 1.5, marginRight: '1rem', marginLeft: '1rem'}}>
            "our journey"
            </div>
        </ScrollAnimation>
        
        <ScrollAnimation duration={2} delay={2} animateIn='fadeInUp' animateOnce={true}>
            <div className='px-3'>
                <div className='w-full rounded-lg'>
                    <YouTube 
                        videoId="B3G9fmOSct0" 
                        opts={opts} 
                        iframeClassName="rounded-lg"
                        onPlay={onPlay}
                        onPause={onPaused}
                        onEnd={onStop}
                    />
                    {/* <video onPlay={onPlay} onPause={onPaused} onStop={onStop} width="100%" height="500" className='rounded-lg' controls>
                        <source src={first_video} type="video/mp4"/>
                    </video> */}
                </div>
            </div>
        </ScrollAnimation>
        <ScrollAnimation duration={2} delay={4} animateIn='fadeInUp' animateOnce={true}>
            <div className='px-3 mt-3'>
            <div className='w-full rounded-lg'>
                    <YouTube 
                        videoId="spgAGjEBeIk" 
                        opts={opts} 
                        iframeClassName="rounded-lg"
                        onPlay={onPlay}
                        onPause={onPaused}
                        onEnd={onStop}
                    />
                    {/* <video onPlay={onPlay} onPause={onPaused} onStop={onStop} width="100%" height="500" className='rounded-lg' controls>
                        <source src={first_video} type="video/mp4"/>
                    </video> */}
                </div>
            </div>
        </ScrollAnimation>
        <PhotoProvider>
            <div className='flex flex-wrap p-3'>
               {images.map((item, index)=>{
                   if (index == 2 || index == 5 || index == 8 || index == 11 || index == 14 || index == 17 || index == 20) {
                    return(
                        <ScrollAnimation key={item.thubnail1} duration={2} delay={4} style={{width: '100%'}} animateIn='fadeInUp' animateOnce={true}>
                            <PhotoView src={item.image1}>
                                <img src={item.thubnail1} className="w-[100%] h-[25vh] object-cover mb-2 rounded-xl" style={{marginRight: index%2 == 0 ? '0.75rem' : '0px'}} alt="" />
                            </PhotoView>
                        </ScrollAnimation>
                       );
                   }
                   return(
                    <>
                    <ScrollAnimation duration={2} delay={4} style={{width: '49%'}} animateIn='fadeInUp' animateOnce={true}>
                        <PhotoView src={item.image1}>
                            <img src={item.thubnail1} className="w-[100%] h-[25vh] object-cover mb-2 rounded-xl" alt="" />
                        </PhotoView>
                     </ScrollAnimation>
                     <div className='w-[2%]'></div>
                     </>
                   );
               })}
            </div>
            </PhotoProvider>
       </div>
    );
}