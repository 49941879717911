
import '../styles/main.css';
import '../styles/styles.css';
import '../styles/welcome-modal.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";

export const Mantra = () => {
    return(
        <div className='mantra-container'>
            <ScrollAnimation duration={2} animateIn='fadeInUp' animateOnce={true}>
                <div className='flower-art'/>
            </ScrollAnimation>
            <div>
                <ScrollAnimation duration={2} delay={2} animateIn='fadeInUp' animateOnce={true}>
                    <span className='text-normal text-i' style={{fontWeight: '500'}}>"Ihaiva stam ma vi yaustam, visvam ayur vyasnutam, kridantau putrair naptrbhih, modamanau sve grhe."</span>
                    <br/>
                    <br/>
                    <span className='text-noarmal text-i'>Rgveda : X.85.42</span>
                    <br/>
                    <br/>
                </ScrollAnimation>
                <ScrollAnimation duration={2} delay={3} animateIn='fadeInUp' animateOnce={true}>
                    <span className='text-noarmal'>- artinya -</span>
                    <br/>
                    <br/>
                    <span className='text-normal'>Wahai pasangan suami-istri, semoga kalian tetap bersatu dan tidak pernah terpisahkan. Semoga kalian mencapai hidup penuh kebahagiaan, tinggal di rumah yang penuh kegembiraan bersama seluruh keturunanmu.</span>
                </ScrollAnimation>
            </div>
        </div>
    );
}