
import '../styles/main.css';
import '../styles/styles.css';
import '../styles/welcome-modal.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";
import bpd from '../assets/images/bpd.png';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useState } from 'react';

export const Gift = () => {
    const [copyRekening1, setCopyRekening1] = useState(false);
    const [copyRekening2, setCopyRekening2] = useState(false);

    const copyNoRek = () => {
    }

    return(
        <div className='mt-6'>
        <ScrollAnimation duration={2} animateIn='fadeInUp' animateOnce={true}>
            <div className='font-gv text-primary mb-md' style={{fontSize: '24pt'}}>Wedding Gift</div>
            <div className="text-normal mx-2">Tanpa mengurangi rasa hormat, bagi rekan-rekan dan sahabat yang hendak memberikan tanda kasih untuk kami, dapat melalui di bawah ini</div>
        </ScrollAnimation>
        <ScrollAnimation duration={2} animateIn='fadeInUp' animateOnce={true}>
            <div className='flex flex-col border-[1px] border-primary rounded-t-lg mx-3 mt-3 p-3 justify-items-center items-center bg-white'>
                    <img src={bpd} className="h-[80px] w-auto"  alt="" />
                    <p className='text-normal'>Silakan transfer ke rekening di bawah ini.</p>
                    <p className='text-normal'>A.n I Made Ardhi Widyantara</p>
                    <p className='text-bold text-primary text-[24px]'>0100215117777</p>
                    <CopyToClipboard text={'0100215117777'}
                        onCopy={() => setCopyRekening1(true)}>
                        <div onClick={copyNoRek} className="m-1 mx-2 h-[40px] flex items-center justify-center px-8 text-white rounded-full border-2 border-primary bg-primary">
                            {copyRekening1 ? 'Berhasil disalin' : 'Salin No. Rekening'}
                        </div>
                    </CopyToClipboard>
            </div>
        </ScrollAnimation>
        <ScrollAnimation duration={2} animateIn='fadeInUp' animateOnce={true}>
            <div className='flex flex-col border-[1px] border-primary rounded-b-lg mx-3 p-3 justify-items-center items-center mt-[-1px] bg-white'>
                    <img src={bpd} className="h-[80px] w-auto"  alt="" />
                    <p className='text-normal'>Silakan transfer ke rekening di bawah ini.</p>
                    <p className='text-normal'>A.n Ni Kadek Sardha Nyana</p>
                    <p className='text-bold text-primary text-[24px]'>0490205021125</p>
                    <CopyToClipboard text={'0490205021125'}
                        onCopy={() => setCopyRekening2(true)}>
                        <div onClick={copyNoRek} className="m-1 mx-2 h-[40px] flex items-center justify-center px-8 text-white rounded-full border-2 border-primary bg-primary">
                         {copyRekening2 ? 'Berhasil disalin' : 'Salin No. Rekening'}
                        </div>
                    </CopyToClipboard>
            </div>
        </ScrollAnimation>
        </div>
    );
}