import './App.css';
import { InvitationPage } from './components/InvitationPage';
import { useEffect, useState } from 'react';
import { WelcomeModal } from './components/WelcomeModal';
import { MusicPlayer } from './components/MusicPlayer';
import musicUrl from './assets/music/music.mp3';
import ReactPlayer from "react-player/lazy";

function App() {
  const [isPlaying, setPlaying] = useState(true);
  const [receviewr, setReceiver] = useState('');
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const query = new URLSearchParams(document.location.search);
    const to = query.get('to');
    setReceiver(to);

    document.addEventListener('gesturestart', function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });
    
    document.addEventListener('gesturechange', function(e) {
        e.preventDefault();
        // special hack to prevent zoom-to-tabs gesture in safari
        document.body.style.zoom = 0.99;
    });
    
    document.addEventListener('gestureend', function(e) {
        e.preventDefault();
        // special hack to prevent zoom-to-tabs gesture in safari
        document.body.style.zoom = 0.99;
    });

    document.body.style.overflow = "no-scroll";

  }, [])

  const closeModal=()=>{
    document.body.style.overflowy = "scroll";
    setShowModal(false);
    _animatedClose();
    setPlaying(false);
    setTimeout(() => {
    setPlaying(true);
    }, 500);
  }

  const _animatedClose = () => {
    const element = document.querySelector('#welcome-modal');
    element.classList.add('animate__slideOutUp');
  }

  return (
    <div className="App">
      <MusicPlayer 
        isPlaying={isPlaying}
        onClick={()=>setPlaying(!isPlaying)}
      />
      
      {!showModal ? 
      <InvitationPage 
        setMusicPlayer={()=>setPlaying(!isPlaying)}
      /> : 
      <WelcomeModal
        onClick={closeModal}
        to={receviewr}
      />
      }
      <ReactPlayer
        url={musicUrl}
        height='0px'
        width="100px"
        playing={isPlaying}
        controls={false}
        loop={true}
      />
    </div>
  );
}

export default App;
