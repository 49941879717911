import React, { useEffect, useState } from 'react';
import '../styles/styles.css';

export const Timer =(props)=>{
    const countdownDate = props.weddingDate ? props.weddingDate.getTime() : new Date().getTime();
    const [timeLeft, setTimeLeft] = useState({
        days: '0',
        hours: '0',
        minutes: '0',
        seconds: '0'
    });

    useEffect(()=>{
        const tick = setInterval(() => setNewTime(), 1000); 
        return () => clearInterval(tick) 
    },[]);

    const setNewTime=()=>{
        if(countdownDate){
            // Get the current time
            const currentTime = new Date().getTime();

            // Get the time remaining until the countdown date
            const distanceToDate = countdownDate - currentTime;

            // Calculate days, hours, minutes and seconds remaining
            let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
            let hours = Math.floor(
            (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
            );
            let minutes = Math.floor(
            (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
            );
            let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

            // For visual appeal, add a zero to each number that's only one digit
            const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

            if (numbersToAddZeroTo.includes(hours)) {
            hours = `0${hours}`;
            } else if (numbersToAddZeroTo.includes(minutes)) {
            minutes = `0${minutes}`;
            } else if (numbersToAddZeroTo.includes(seconds)) {
            seconds = `0${seconds}`;
            }   
            setTimeLeft({
                days,
                hours,
                minutes,
                seconds
            });
        }
    }
    
    return(
        <div className='time-component-wrapper'>
            <div className='timer'>
                <div className='text-medium text-bold' style={{fontSize: 24}}>{timeLeft.days}</div>
                <span className='text-normal'>Hari</span>
            </div>
            <div className='timer'>
                <div className='text-medium text-bold' style={{fontSize: 24}}>{timeLeft.hours}</div>
                <span className='text-normal'>Jam</span>
            </div>
            <div className='timer'>
                <div className='text-medium text-bold' style={{fontSize: 24}}>{timeLeft.minutes}</div>
                <span className='text-normal'>Menit</span>
            </div>
            <div className='timer'>
                <div className='text-medium text-bold' style={{fontSize: 24}}>{timeLeft.seconds}</div>
                <span className='text-normal'>Detik</span>
            </div>
        </div>
    );
}