// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCURS2E3eDGAMfrf7LkfyENYaSvOBW_vig",
  authDomain: "tresna-heny.firebaseapp.com",
  projectId: "tresna-heny",
  storageBucket: "tresna-heny.appspot.com",
  messagingSenderId: "740912101201",
  appId: "1:740912101201:web:85efb128189155b3c0c570",
  databaseURL: 'https://tresna-heny-default-rtdb.asia-southeast1.firebasedatabase.app/'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);