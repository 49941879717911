
import '../styles/main.css';
import '../styles/styles.css';
import '../styles/welcome-modal.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";
export const Reseption = () => {
    const showInMapClicked = () => {
        window.open("https://maps.google.com?q=-8.343939113411551, 115.39060136278");
    };
    return(
        <div>
            <div className='w-[50vw] bg-primary py-2'>
                <div className='font-gv text-[30px] text-white'>Acara Resepsi</div>
            </div>
            <div className='resepsi-wrapper relative'>
                <div className='absolute w-[45%] h-[100%]'>
                    <div className='flex flex-col p-2 mt-8 items-center'>
                        <div className='text-normal text-white'>11:00 WITA - Selesai</div>
                        <div className='date-reseption mt-8'>
                            <div className='text-medium text-white text-bold' style={{fontSize: 24}}>19</div>
                            <div className='text-normal text-white'>September</div>
                            <div className='text-normal text-white'>2023</div>
                        </div>
                    </div>
                    <div className='absolute left-4 bottom-2 pr-2 text-left'>
                        <div className='text-[12px] text-white leading-none'>Br Biya Keramas Blahbatuh Gianyar</div>
                    </div>
                </div>
            </div>   
            <div className='flex w-full'>
                <div className='bg-primary py-2 w-full'>
                    <div onClick={showInMapClicked} className="m-1 mx-2 h-[40px] flex items-center justify-center px-8 text-white rounded-full border-2 border-white">
                        Buka Map
                    </div>
                </div>
            </div>
        </div>
    );
}