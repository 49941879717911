
import '../styles/main.css';
import '../styles/styles.css';
import '../styles/welcome-modal.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";
import 'react-awesome-slider/dist/styles.css';

export const CoupleIdentity = () => {
    return(
        <div className='py-8'>
            <div className='mt-lg'/>
                <ScrollAnimation duration={2} animateIn='fadeInUp' animateOnce={true}>
                    <span className='font-gv text-primary' style={{fontSize: '24pt', marginTop: '4rem'}}>Om Swastyastu</span>
                </ScrollAnimation>
                <div className='mt-md'/>
                <ScrollAnimation duration={2} delay={2} animateIn='fadeInUp' animateOnce={true}>
                    <div className='text-normal text-primary mx-6 mb-10' style={{lineHeight: '1.2'}}>Atas Asung Kertha Wara Nugraha Ida Sang Hyang Widhi Wasa/Tuhan Yang Maha Esa kami bermaksud mengundang Bapak/Ibu/Saudara/i pada Upacara Manusa Yadnya Pawiwahan (Pernikahan) kami.</div>
                </ScrollAnimation>

                <div className='flex h-[60vh]'>
                <ScrollAnimation duration={2} animateIn='fadeInLeft' animateOnce={true} className='w-[80px] flex justify-center items-center transform font-gv text-primary text-[50px]'>
                    <div className='rotate-180' style={{ writingMode: 'vertical-rl' }}>
                        Ardhi
                    </div>
                </ScrollAnimation>
                <ScrollAnimation duration={2} animateIn='fadeInRight' animateOnce={true} className='relative flex w-[100%] h-[100%]'>
                    <div className='bg-[yellow] h-[100%] w-[100%] male-photo text-[transparent]'>A</div>
                    <div className='absolute w-full h-full'></div>
                    <div className='bg-[rgba(255,255,255,0.7)] w-[100%] p-[2] text-left p-2 absolute bottom-0'>
                        <div className='subtitle text-[18px] text-primary font-bold'>I Made Ardhi Widyantara, Amd.Par</div>
                        <div className='text-[16px]'>Anak kedua dari Bapak I Wayan Karmada dan Ibu Ni Ketut Netriyani</div>
                        <div className='text-[12px] font-bold'>Br Biya Keramas Blahbatuh Gianyar</div>
                    </div>
                </ScrollAnimation>
            </div>
            {/* female */}
            <div className='flex w-full h-[60vh] mt-8 relative'>
                <ScrollAnimation duration={2} animateIn='fadeInLeft' animateOnce={true} className='flex w-[100%] h-[100%] relative'>
                    <div className='bg-[yellow] h-[100%] w-[100%] female-photo text-[transparent]'>A</div>
                    <div className='absolute w-full h-full'></div>
                    <div className='bg-[rgba(255,255,255,0.7)] w-[100%] p-[2] text-left p-2 absolute bottom-0 z-50'>
                        <div className='subtitle text-[18px] text-primary font-bold'>Ni Kadek Sardha Nyana, S.Pd</div>
                        <div className='text-[16px]'>Anak kedua dari Bapak I Wayan Nyana dan Ibu Ni Ketut Wartini</div>
                        <div className='text-[12px] font-bold'>Br Anggarkasih, medahan blahbatuh Gianyar</div>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation duration={2} animateIn='fadeInRight' animateOnce={true}  className='w-[80px] flex justify-center items-center rotate-90 font-gv text-primary text-[50px]'>
                    <div className='transform rotate-240' style={{ writingMode: 'vertical-rl' }}>
                        Sardha
                    </div>
                </ScrollAnimation>
            </div>
        </div>  
    );
}