
import '../styles/main.css';
import '../styles/styles.css';
import '../styles/welcome-modal.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";

export const Quote = () => {
    return(
        <div className=''>
            <div className='flex'>
                <ScrollAnimation delay={2} className='bg-primary w-80 border-o' duration={2} animateIn='fadeInLeft' animateOnce={true}>
                </ScrollAnimation>
                <div>
                    <ScrollAnimation delay={4} duration={2} animateIn='fadeInUp' animateOnce={true}>
                        <div className='flex'>
                            <div className='w-[55vw] h-[40vh] ml-[-50px] mt-8 quote-image absolute'>
                            </div>
                            <div className='w-[55vw] h-[40vh] z-1 mt-20 ml-2 quote-image-back'>
                            </div>
                        </div>
                        
                        <ScrollAnimation delay={6} duration={2} animateIn='fadeInUp' animateOnce={true}>
                            <div className='p-2 text-normal my-2 text-[8px]'>
                            Love is not about how many days, months, or years you have been together. Love is about how much you love each other every single day.
                            </div>
                        </ScrollAnimation>

                        <ScrollAnimation delay={8} duration={2} animateIn='fadeInRight' animateOnce={true}>
                            <div className='bg-primary w-width h-[5vh] mt-[-2px] border-0'>
                            </div>
                        </ScrollAnimation>
                    </ScrollAnimation>
                </div>
            </div>
        </div>  
    );
}