import '../styles/main.css';
import '../styles/styles.css';
import '../styles/welcome-modal.css';
import 'react-photo-view/dist/react-photo-view.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

export const Moment = () => {
    return(
       <div>
        <ScrollAnimation duration={2} animateIn='fadeInUp' animateOnce={true}>
            <div className='font-gv text-primary mb-md' style={{fontSize: '24pt'}}>Momen Bahagia</div>
        </ScrollAnimation>
        <div className='flex w-full bg-primary relative'>
            <div className='h-full w-[80%] mt-[3px]'>
                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={3000}
                    bullets={false}
                    disabled={true}
                >
                    <div className='h-[100%] w-[100%] momen-slide-1-1 p-1 text-[transparent]'>
                        <div className='border-2 border-[white] h-[100%] w-[100%] p-[2px]'>
                            <div className='border-2 border-[white] h-[100%] w-[100%]'></div>
                        </div>
                    </div>
                    <div className='h-[100%] w-[100%] momen-slide-1-2 p-1 text-[transparent]'>
                        <div className='border-2 border-[white] h-[100%] w-[100%] p-[2px]'>
                            <div className='border-2 border-[white] h-[100%] w-[100%]'></div>
                        </div>
                    </div>
                    <div className='h-[100%] w-[100%] momen-slide-1-3 p-1 text-[transparent]'>
                        <div className='border-2 border-[white] h-[100%] w-[100%] p-[2px]'>
                            <div className='border-2 border-[white] h-[100%] w-[100%]'></div>
                        </div>
                    </div>
                </AutoplaySlider>

            </div>
            <div className=' flex w-[20%] h-[100%] items-center justify-center'>
                <div className='w-[100%] h-[24vh] floral-1 floral-1 mt-10'/>
            </div>
        </div>

        <div className='josefin-sans text-[14px] text-left p-3'>
        A successful marriage requires falling in love many times, always with the same person.
        </div>

        <div className='flex w-full bg-primary items-center'>
            <div className='flex w-[20%] h-[100%] items-center justify-center'>
                <div className='w-[100%] h-[24vh] floral-1 mt-10'/>
            </div>

            <div className='h-full w-[80%] rotate-180'>
                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={3000}
                    bullets={false}
                >
                    <div className='h-[100%] w-[100%] momen-slide-2-1 p-1 text-[transparent] rotate-180'>
                        <div className='border-2 border-[white] h-[100%] w-[100%] p-[2px]'>
                            <div className='border-2 border-[white] h-[100%] w-[100%]'></div>
                        </div>
                    </div>
                    <div className='h-[100%] w-[100%] momen-slide-2-2 p-1 text-[transparent] rotate-180'>
                        <div className='border-2 border-[white] h-[100%] w-[100%] p-[2px]'>
                            <div className='border-2 border-[white] h-[100%] w-[100%]'></div>
                        </div>
                    </div>
                    <div className='h-[100%] w-[100%] momen-slide-2-3 p-1 text-[transparent] rotate-180'>
                        <div className='border-2 border-[white] h-[100%] w-[100%] p-[2px]'>
                            <div className='border-2 border-[white] h-[100%] w-[100%]'></div>
                        </div>
                    </div>
                </AutoplaySlider>
            </div>
        </div>
        <div className='josefin-sans text-[14px] text-right p-3'>
        True love stands by each other's side on good days and stands closer on bad days.
        </div>

        <div className='flex w-full bg-primary'>
            <div className='h-full w-[80%] mt-[3px]'>
                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={3000}
                    bullets={false}
                >
                    <div className='h-[100%] w-[100%] momen-slide-3-1 p-1 text-[transparent]'>
                        <div className='border-2 border-[white] h-[100%] w-[100%] p-[2px]'>
                            <div className='border-2 border-[white] h-[100%] w-[100%]'></div>
                        </div>
                    </div>
                    <div className='h-[100%] w-[100%] momen-slide-3-2 p-1 text-[transparent]'>
                        <div className='border-2 border-[white] h-[100%] w-[100%] p-[2px]'>
                            <div className='border-2 border-[white] h-[100%] w-[100%]'></div>
                        </div>
                    </div>
                    <div className='h-[100%] w-[100%] momen-slide-3-3 p-1 text-[transparent]'>
                        <div className='border-2 border-[white] h-[100%] w-[100%] p-[2px]'>
                            <div className='border-2 border-[white] h-[100%] w-[100%]'></div>
                        </div>
                    </div>
                </AutoplaySlider>
            </div>
            <div className=' flex w-[20%] h-[100%] items-center justify-center'>
                <div className='w-[100%] h-[24vh] floral-1 mt-10'/>
            </div>
        </div>
        <div className='josefin-sans text-[14px] text-left p-3'>
        Real love stories never have endings but continue to grow and flourish over time.
        </div>
       </div>
    );
}