import '../styles/main.css';
import '../styles/styles.css';
import '../styles/welcome-modal.css';

export const SliderWelcome = () => {
    return(
        <div className='fixed w-full h-full'>
            <div className='slideModal1'></div>
            <div className='slideModal2'></div>
        </div>
    );
}