import './../styles/styles.css';
import './../styles/welcome-modal.css';
import 'animate.css';
import { SliderWelcome } from './SliderWelcome';

export const WelcomeModal = (props) => {

    return(
        <div id='welcome-modal' className="w-full h-full max-w-full max-h-full relative welcome-modal-backdrop animate__animated animate__slideInDown">
            <SliderWelcome />
           <div className="backdrop-overlay py-8">
                <div className='subtitle light-text mb-4'>
                    The Wedding of
                </div>
                <div className="couple-name margin-top-md animate__animated animate__fadeInLeft">
                    Ardhi & Sardha
                </div>
                <div className='subtitle text-white mt-4'>
                    Kamis, 19 September 2024
                </div>
                <div className='h-[50vh]'></div>
                <div className='subtitle text-white text-i animate__animated animate__fadeInRight'>Dear:</div>
                <div className='text-medium text-white mt-md animate__animated animate__fadeInLeft'>{props.to}</div>
                <div className='subtitle text-white mt-md text-i animate__animated animate__fadeInRight'>You're invited</div>
                <div onClick={props.onClick} className="open-invitation-btn mt-lg text-bold animate__animated animate__fadeInLeft">
                    Buka Undangan
                </div>
           </div>
        </div> 
    );
}